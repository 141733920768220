function  limit(httpsRequest) {
 return {
  getMenuPageList(params) {
   return httpsRequest('/as.m.auth.getMenuPageList/1.0.0/action', params)
  },
  insertMenu(params) {
    return httpsRequest('/as.m.auth.insertMenu/1.0.0/action', params)
   },
   updateMenu(params) {
    return httpsRequest('/as.m.auth.updateMenu/1.0.0/action', params)
   },
   delMenu(params) {
    return httpsRequest('/as.m.auth.delMenu/1.0.0/action', params)
   },
   getRoleMenuList(params) {
    return httpsRequest('/as.m.auth.getRoleMenuList/1.0.0/action', params)
   },
   insertRole(params) {
    return httpsRequest('/as.m.auth.insertRole/1.0.0/action', params)
   },
   updateRole(params) {
    return httpsRequest('/as.m.auth.updateRole/1.0.0/action', params)
   },
   delRole(params) {
    return httpsRequest('/as.m.auth.delRole/1.0.0/action', params)
   },
   
  }
}
export {limit}