/**
 * @module main/store/modules/permission
 * @description 权限相关的store
 */

 
 const state = function () {
   return {
     // 后台返回的菜单包括按钮
     routes: [],
     defaultActive:''
   }
 }
 
 const getters = {
 
 }
 
 const actions = {}
 
 const mutations = {
   SET_ROUTES(state, menus) {
     state.routes = menus
   },
 }
 
 export default {
   namespaced: true,
   state,
   getters,
   actions,
   mutations,
 }
 