<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // params: {
      //   hospitalname: "",
      //   userlevel: "",
      //   showparent: 1,
      //   categorycode: "",
      //   page: "1",
      //   pageSize: this.$utils.getSize(),
      //   provinceid: "",
      //   cityid: "",
      //   districtid: "",
      // },
    };
  },
  async mounted() {
    // const res = await this.$https.requertReport(this.params);
    // console.log("res", res);
  },
};
</script>

<style>
</style>