import axios from "axios";
import Cookies from 'js-cookie/src/js.cookie';
import qs from 'qs';

const service = axios.create({
    baseURL: '/api',
    timeout: 30 * 1000, // 请求超时时间
    headers: {
        'asai_id': Cookies.get('token'),
        'Content-Type': 'application/x-www-form-urlencoded'
    }
});

service.interceptors.request.use(
  config => {
      config.data = qs.stringify(config.data); // 转为formdata数据格式
      return config;
  },
  error => {
      console.log(error);
      Promise.reject(error);
  }
);
export default service;
