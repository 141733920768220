const state={
 userInfo:JSON.parse(localStorage.getItem('logindata'))
}
const getters = {

}
const mutations = {

}
const actions = {}
export default {
 namespaced: true,
 state,
 getters,
 mutations,
 actions
}