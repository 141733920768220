
import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css' //这个样式必须引入
import Cookies from 'js-cookie/src/js.cookie'
import { echartsRouter } from './model/echarts'
// //页面全引用在路由里
Vue.use(VueRouter)
import hospitalDetail from '../views/hospital/detail'
import hospitalList from '../views/hospital/list'
import hospital from '../views/hospital/index.vue'
let loginData;
const routes = [
  {
    path: '/Login',
    name: '登录',
    component: () => import('../views/Login')
  },
  {
    path: '/404',
    name: '未找到页面',
    component: () => import('../views/wights/404')
  },
  {
    path: '/401',
    name: '无访问权限',
    component: () => import('../views/wights/401')
  },
  {
    path: '/viewreportdetails',
    name: '测评报告',
    component: () => import('../components/ViewReportDetails')
  },
  // 权限配置问题,不要在下方列表进行嵌套路由
  {
    path: '/dashboard',
    component: () => import('../views/Index'),
    children: [
      {
        path: '**/404',
        name: '未找到页面',
        component: () => import('../views/wights/404')
      },
      {
        path: '**/401',
        name: '无访问权限',
        component: () => import('../views/wights/401')
      },
      {
        path: '/dashboard/statement/userdata',
        name: '用户管理',
        component: () => import('../views/statement/UserDataList'),
      },
      {
        path: '/dashboard/statement/ordercount',
        name: '订单管理',
        component: () => import('../views/statement/OrderHistory'),
      },
      {
        path: '/dashboard/setting/rolelist',
        name: '角色管理',
        component: () => import('../views/setting/RoleManager'),
      },
      {
        path: '/dashboard/setting/userlist',
        name: '用户列表',
        component: () => import('../views/setting/UsersManager'),
      },
      // {
      //   path: '/dashboard/setting/orglist',
      //   name: '组织管理',echarts/web
      //   component: () => import('../views/setting/OrgManager'),
      // },
      {
        path: '/dashboard/statement/hospitallist',
        name: '医院列表',
        component: () => import('../views/statement/HospitalList'),
      },
      {
        path: '/dashboard/statement/reportment',
        name: '报告管理',
        component: () => import('../views/statement/AccessReportList'),
      },
      {
        path: '/dashboard/statement/reportDetail',
        name: '报告详情',
        component: () => import('../views/statement/UserProfile'),
      },
      {
        path: '/dashboard/statement/reportquota',
        name: '评估配置',
        component: () => import('../views/statement/ReportQuotaList'),
      },
      {
        path: '/dashboard/statement/operatedashboard',
        name: '经营统计',
        component: () => import('../views/statement/OperateDashboard'),
      },
      {
        path: '/dashboard/statement/agenttongji',
        name: '统计管理',
        component: () => import('../views/statement/TongJi'),
      },
      {
        path: '/dashboard/setting/userscategory',
        name: '代理关系',
        component: () => import('../views/setting/UsersCategoryManager'),
      },
      {
        path: '/dashboard/setting/hospitalmanager',
        name: '医院代理关系',
        component: () => import('../views/setting/HospitalManager'),
      },
      {
        path: '/dashboard/setting/permissions',
        name: '权限配置',
        component: () => import('../views/setting/permissions'),
      },
      {
        path: '/dashboard/setting/roleAdmin',
        name: '权限管理',
        component: () => import('../views/setting/roleAdmin'),
      },
      {
        path: '/dashboard/hospital',
        name: '医院管理',
        component: hospital,
      },
      {
        path: '/dashboard/hospital/detail',
        name: '详情',
        component: hospitalDetail,
      },
      {
        path: '/dashboard/hospital/list',
        name: '医院管理列表',
        component: hospitalList,
      },
      {
        path: '/dashboard/statement/orderobtain',
        name: '授权使用',
        component: () => import('../views/statement/OrderObtain'),
      }
    ]
  },
  ...echartsRouter,
]
console.log(routes)
const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  NProgress.start();
  NProgress.done();
  if (to.path.search("/dashboard") !== -1){
    //如果进入主界面

    loginData = JSON.parse(localStorage.getItem("logindata"));
    if (!loginData || !Cookies.get("token")){
      //登录信息失效
      console.log('登录信息失效')
      next('/Login');
    } else {
      if (to.matched.length === 0){
        next(to.path + '/404');
      }else{
        next();
      }
    }
  }else{
    if (to.matched.length === 0){
      next('/404');
    }else{
      next();
    }
  }

  /**
   * next(): 进行管道中的下一个钩子。如果全部钩子执行完了，则导航的状态就是 confirmed (确认的)。

   next(false): 中断当前的导航。如果浏览器的 URL 改变了 (可能是用户手动或者浏览器后退按钮)，那么 URL 地址会重置到 from 路由对应的地址。

   next('/') 或者 next({ path: '/' }): 跳转到一个不同的地址。当前的导航被中断，然后进行一个新的导航。你可以向 next 传递任意位置对象，且允许设置诸如 replace: true、name: 'home' 之类的选项以及任何用在 router-link 的 to prop 或 router.push 中的选项。

   next(error): (2.4.0+) 如果传入 next 的参数是一个 Error 实例，则导航会被终止且该错误会被传递给 router.onError() 注册过的回调。
   */

});

export default router
