const echartsRouter = [
 {
  path: '/echarts/h5',
  name: '详情',
  component: () => import('@/views/echarts/h5/index')
  },
  {
    path: '/echarts/app',
    name: '详情',
    component: () => import('@/views/echarts/app.vue')
  },
  {
    path: '/echarts/web',
    name: '详情',
    component: () => import('@/views/echarts/web/index')
  },
]
export {echartsRouter}