<template>
  <div class="cris-container-admin">
    <router-view></router-view>
    <el-form :inline="true" label-position="left">
      <el-form-item label="医院名称">
        <el-input clearable v-model="params.hospitalname"></el-input>
      </el-form-item>
      <el-form-item label="省市区">
        <el-cascader
          size="large"
          :options="areaList"
          v-model="userarea"
          @change="handleChange"
          :props="{ checkStrictly: true }"
          clearable
          style="width: 300px"
        >
        </el-cascader>
      </el-form-item>
    </el-form>
    <el-button
      type="primary"
      @click="$router.push('/dashboard/hospital/detail')"
      v-if="rolecode"
    >
      录入医院
    </el-button>
    <el-table :data="list" v-loading="loading">
      <el-table-column label="序号" type="index"></el-table-column>
      <el-table-column prop="hospitalname" label="医院名字" />
      <el-table-column label="医院地址" width="160">
        <template slot-scope="{ row }">
          <div>
            {{ row.provincename }} {{ row.cityname }} {{ row.districtname }}
          </div>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="userfullname" label="医院联系人">
        <template>
          {{ userInfo.userfullname }}
        </template>
      </el-table-column> -->
      <el-table-column prop="channeltype" label="医院来源">
        <template slot-scope="{ row }">
          <div v-if="row.channeltype == 0">公司业务员发展的医院</div>
          <div v-else>妇幼保健协会推广的医院</div>
        </template>
      </el-table-column>

      <el-table-column prop="channeltype" label="医院联系人">
        <template slot-scope="{ row }">
          <div v-if="row.parent && row.parent.userfullname">
            {{ row.parent.userfullname }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="channeltype" label="上级服务商">
        <template slot-scope="{ row }">
          <div v-if="row.parent && row.parent.userfullname">
            {{ row.parent.userfullname }}
          </div>
          <!-- <div
            v-if="
              row.category3info &&
              row.category3info.userfullname &&
              row.category3info.categorycode == row.categorycode
            "
          >
            {{ row.category2info.userfullname }}
          </div>
          <div v-else-if="row.category2info && row.category2info.userfullname">
            {{ row.category2info.userfullname }}
          </div>
          <div v-else-if="row.category1info && row.category1info.userfullname">
            {{ row.category1info.userfullname }}
          </div>
          <div v-else-if="row.category0info && row.category0info.userfullname">
            {{ row.category0info.userfullname }}
          </div> -->
        </template>
      </el-table-column>
      <el-table-column prop="createtime" label="医院创建时间" width="170" />
      <el-table-column prop="reportnum" label="已生成报告数" />
      <el-table-column label="操作" width="300px" align="center" fixed="right">
        <template slot-scope="{ row }">
          <el-button
            class="btn"
            type="text"
            size="mini"
            @click="look(row)"
            :loading="btnLoading"
          >
            查看
          </el-button>
          <el-button
            class="btn"
            type="text"
            size="mini"
            @click="addCategory(row)"
            v-if="rolecode"
          >
            绑定上级服务商
          </el-button>
          <el-button
            class="btn"
            type="text"
            size="mini"
            @click="look(row)"
            v-if="rolecode"
          >
            设置免费次数
          </el-button>
          <el-button
            v-if="row.qrcodeurl"
            @click="downloadQRCode(row)"
            type="text"
            size="small"
            >下载二维码</el-button
          >
          <el-button v-else @click="createQRCode(row)" type="text" size="small"
            >创建二维码</el-button
          >
        </template>
      </el-table-column>

      <div slot="empty">
        <!-- <cubebase-table-empty /> -->
      </div>
    </el-table>
    <div class="footer">
      <el-pagination
        background
        @current-change="handleCurrentChange"
        :page-sizes="[15, 30]"
        :page-size="params.pageSize"
        style="text-align: center"
        layout="total,sizes, prev, pager, next, jumper"
        :total="info.totalRecords"
      >
      </el-pagination>
    </div>
    <el-dialog title="详情" append-to-body :visible.sync="dialogVisible">
      <el-form label-width="120px">
        <el-form-item label="医院登录账号">
          <el-input
            clearable
            v-model="detail.username"
            disabled
            style="width: 360px"
          ></el-input>
          <el-button
            @click="copy"
            id="copy-text"
            :data-clipboard-text="detail.username"
            style="margin-left: 10px"
            type="primary"
            >复制</el-button
          ></el-form-item
        >

        <el-form-item label="医院录入人">
          <el-input clearable v-model="detail.createby" disabled></el-input
        ></el-form-item>
        <el-form-item label="医院二维码" v-if="rolecode">
          <img
            v-if="detail.qrcodeurl"
            :src="detail.qrcodeurl"
            alt=""
            width="150"
          />
          <div>
            <el-button
              v-if="detail.qrcodeurl"
              @click="downloadQRCode(detail)"
              type="text"
              size="small"
              >下载二维码</el-button
            >
            <el-button v-else type="text">暂无</el-button>
          </div>
        </el-form-item>
        <el-form-item label="免费使用">
          <el-switch
              v-model = detail.isfree
              active-value="1"
              inactive-value="0"
              @change="handleSwitchChange(detail)"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="医院免费次数">
          <el-input
            clearable
            style="width: 400px"
            v-model="detail.freecount"
            disabled
          ></el-input>
          <el-button
            v-if="rolecode"
            @click="updateFreeCount(detail.hospitalcode)"
            class="ml10"
            type="primary"
          >
            修改次数
          </el-button></el-form-item
        >
        <!-- <el-form-item label="医院当免费次数">
          <el-input clearable v-model="detail.categorycode"></el-input
        ></el-form-item> -->
        <el-form-item label="医院当前剩余免费次数">
          <el-input clearable v-model="detail.lastfreecount" disabled></el-input
        ></el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog
      append-to-body
      title="绑定上级服务商"
      :visible.sync="addCategoryDialog"
      v-if="addCategoryDialog"
      width="960px"
    >
      <add-category
        :current="viewDetailObj"
        :closeAndRefresh="closeAndRefresh"
      ></add-category>
    </el-dialog>
  </div>
</template>

<script>
import Clipboard from "clipboard";
import AddCategory from "@/components/cris-dash-host";
import { regionData } from "element-china-area-data";
// import { mapState } from "vuex";
import axios from "axios";
export default {
  components: { AddCategory },
  data() {
    return {
      areaList: [],
      loading: false,
      btnLoading: false,
      options: regionData,
      selectedOptions: [],
      list: [],
      dialogVisible: false,
      props: { multiple: false },
      info: {},
      detail: {},
      userarea: [],
      isfree:false,
      addCategoryDialog: false,
      params: {
        hospitalname: "",
        userlevel: "",
        showparent: 1,
        categorycode: "",
        page: "1",
        pageSize: this.$utils.getSize(),
        provinceid: "",
        cityid: "",
        districtid: "",
      },
      rolecode: false,
      viewDetailObj: {},
      userInfo: JSON.parse(localStorage.getItem("logindata")),
      url:
        "https://restapi.amap.com/v3/config/district?key=4f3b15d4e9ec39a20d3fb459c65ecf17",
    };
  },
  computed: {
    // ...mapState({
    //   userInfo: (state) => state.user.userInfo,
    // }),
  },
  watch: {
    "params.hospitalname"() {
      this.asyncData();
    },
  },
  mounted() {
    this.loginData = JSON.parse(localStorage.getItem("logindata")) || {};
    let showAll = this.loginData.urls.find((e) => e.url === "hospital-code"); //是否没有医院全部权限
    if (showAll) this.rolecode = true; //如果code为false则没有医院全部权限
    this.asyncData();
    this.onLevelChange("3");
  },
  methods: {
    handleSwitchChange(row){
      console.log(row)
      console.log('Switch value changed:', row.isfree);
      let param = {
        hospitalcode:row.hospitalcode,
        isfree:row.isfree
      }
      this.$api
          .updateHospitalFreeStatus(param).then(
          (res) => {
            console.log(res);
          }
      )
    },
    asyncData() {
      this.loading = true;
      this.params.userlevel = this.userInfo ? this.userInfo.userlevel : "";
      this.params.categorycode = this.userInfo
        ? this.userInfo.categorycode
        : "";
      this.$api
        .getRportList(this.params)
        .then((res) => {
          if (res.data.success == 1) {
            this.loading = false;
            this.info = res.data.data;
            this.list = res.data.data.list;
          } else {
            this.$message({
              showClose: true,
              message: res.data.errorMsg,
              type: "warning",
            });
          }
        })
        .catch(() => {
          this.loading = false;
          this.$message({
            showClose: true,
            message: "请检查网络连接",
            type: "warning",
          });
        });
    },
    copy() {
      var btn = document.getElementById("copy-text");
      var clipboard = new Clipboard(btn);
      clipboard.on("success", () => {
        this.$message({
          message: "复制成功",
          type: "success",
        });
        clipboard.destroy();
      });
      clipboard.on("error", () => {
        // 不支持复制
        console.log("该浏览器不支持自动复制");
        // 释放内存
        clipboard.destroy();
      });
    },
    onLevelChange(value) {
      let url;
      /**
       * userlevel用户等级	String	1 = 省级代理   2=市级代理   3=代理人   4=医院
       */
      switch (value) {
        case "1":
          url = this.url + "&subdistrict=1";
          break;
        case "2":
          url = this.url + "&subdistrict=2";
          break;
        case "3":
          url = this.url + "&subdistrict=3";
          break;
        case "4":
          url = this.url + "&subdistrict=3";
          break;
      }
      // this.formData.userlevel = value;
      this.loading = true;
      axios.get(url).then((res) => {
        let list = [];
        for (let pIndex in res.data.districts[0].districts) {
          let pItem = res.data.districts[0].districts[pIndex];
          let value = {};
          value.provinceid = pItem.adcode;
          value.label = pItem.name;
          value.value = pItem.adcode;
          if (pItem.districts && pItem.districts.length > 0) {
            value.children = [];
          }
          for (let cIndex in pItem.districts) {
            let cItem = pItem.districts[cIndex];
            let cValue = {
              cityid: cItem.adcode,
              label: cItem.name,
              value: cItem.adcode,
            };
            if (cItem.districts && cItem.districts.length > 0) {
              cValue.children = [];
            }
            for (let dIndex in cItem.districts) {
              let dItem = cItem.districts[dIndex];
              cValue.children.push({
                districtid: dItem.adcode,
                label: dItem.name,
                value: dItem.adcode,
              });
            }
            value.children.push(cValue);
          }
          list.push(value);
        }
        this.areaList = list;
        this.loading = false;
      });
    },
    handleCurrentChange(val) {
      this.params.page = val;
      this.asyncData();
    },
    look(row) {
      this.btnLoading = true;
      this.$api
        .getReportDetail({
          categorycode: row.categorycode,
        })
        .then((res) => {
          this.btnLoading = false;
          this.detail = res.data.data;
          this.dialogVisible = true;
        });
    },
    async createQRCode(row) {
      this.loading = true;
      await this.$api
        .createHospitalQRCode({
          hospitalcode: row.hospitalcode,
          scenecode: row.scenecode,
        })
        .then((res) => {
          this.loading = false;
          if (res.data.success === "1") {
            this.$message.success("创建成功");
            this.asyncData();
          } else {
            this.$message({
              showClose: true,
              message: res.data.errorMsg,
              type: "warning",
            });
          }
        })
        .catch(() => {
          this.loading = false;
          this.$message({
            showClose: true,
            message: "请检查网络连接",
            type: "warning",
          });
        });
    },
    downloadQRCode(row) {
      window.open(row.qrcodeurl);
    },
    updateFreeCount(hospitalcode) {
      this.$prompt("请输入免费次数", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /^[0-9]*$/,
        inputErrorMessage: "输入数字有误，请重新输入",
      }).then(({ value }) => {
        this.loading = true;
        this.$api
          .setHospitalFreeCount({
            hospitalcode: hospitalcode,
            freecount: value,
          })
          .then((res) => {
            if (res.data.success === "1") {
              this.$message({
                showClose: true,
                message: "设置成功",
                type: "success",
              });
              this.dialogVisible = false;
            } else {
              this.$message({
                showClose: true,
                message: res.data.errorMsg,
                type: "warning",
              });
            }
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
            this.$message({
              showClose: true,
              message: "请检查网络连接",
              type: "warning",
            });
          });
      });
    },
    addCategory(row) {
      this.viewDetailObj = row;
      this.addCategoryDialog = true;
    },
    handleChange() {
      this.params.provinceid = this.userarea[0];
      this.params.cityid = this.userarea[1];
      this.params.districtid = this.userarea[2];
      this.asyncData();
    },
    closeAndRefresh(currentcode, targetcode) {
      this.$api
        .updateHospitalCategory({
          currentcode: currentcode,
          targetcode: targetcode,
        })
        .then((res) => {
          if (res.data.success === "1") {
            this.addCategoryDialog = false;
            this.$message({
              showClose: true,
              message: "绑定成功",
              type: "success",
            });
            this.asyncData();
          } else {
            this.$message({
              showClose: true,
              message: res.data.errorMsg,
              type: "warning",
            });
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.$message({
            showClose: true,
            message: "请检查网络连接",
            type: "warning",
          });
        });
    },
  },
};
</script>

<style scoped lang="scss">
.ml10 {
  margin-left: 10px;
}
.footer {
  display: flex;
  height: 100px;
  justify-content: center;
  align-items: center;
}
</style>