import Vue from 'vue';
import App from './App.vue';
import router from './router';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Cookies from 'js-cookie/src/js.cookie';
import Router from 'vue-router';
import md5 from 'blueimp-md5';
import api from '../src/api/index.js'
import '@/variables.scss'
import utils from './utils/index'
import store from './store/index'
import https from './service/index'
Vue.prototype.$https = https
Vue.prototype.$api = api
Vue.prototype.$utils = utils
Vue.use(Router);
// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = Router.prototype.push;

Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.config.productionTip = false;
console.log(process.env.NODE_ENV)
Vue.prototype.$md5 = md5;

Vue.use(ElementUI);

Vue.prototype.$Cookies = Cookies;

Vue.prototype.$dateFormat = dateFormat;

new Vue({
  store:store,
  router,
  render: h => h(App),
}).$mount('#app')


function dateFormat(fmt, date) {
  let ret;
  const opt = {
    "Y+": date.getFullYear().toString(),        // 年
    "m+": (date.getMonth() + 1).toString(),     // 月
    "d+": date.getDate().toString(),            // 日
    "H+": date.getHours().toString(),           // 时
    "M+": date.getMinutes().toString(),         // 分
    "S+": date.getSeconds().toString()          // 秒
  };
  for (let k in opt) {
    ret = new RegExp("(" + k + ")").exec(fmt);
    if (ret) {
      fmt = fmt.replace(ret[1], (ret[1].length === 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
    }
  }
  return fmt;
}