<template>
  <div class="cris-container-admin">
    <el-form label-width="130px" :rules="rules" ref="ruleForm" :model="params">
      <el-form-item label="医院名字" prop="userfullname">
        <el-input clearable v-model="params.userfullname"></el-input
      ></el-form-item>
      <el-form-item label="医院登录账户" prop="username">
        <el-input clearable v-model="params.username"></el-input
      ></el-form-item>
      <el-form-item label="账户密码" prop="password">
        <el-input clearable v-model="params.password"></el-input
      ></el-form-item>
      <el-form-item label="角色">
        <!-- <el-input clearable v-model="params.rolename"></el-input -->
        <el-select v-model="params.rolecode" @change="onRoleChange" disabled>
          <el-option
            v-for="item in roleList"
            :key="item.rolecode"
            :label="item.rolename"
            :value="item.rolecode"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="账户等级">
        <el-input clearable disabled v-model="params.categoryname"></el-input
      ></el-form-item>
      <el-form-item label="医院渠道">
        <el-radio-group v-model="params.channeltype">
          <el-radio :label="0">自己发展的医院</el-radio>
          <el-radio :label="1">妇幼保健协会的医院</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="代理区域" prop="userarea">
        <el-cascader
          style="width: 65%"
          v-model="params.userarea"
          :options="areaList"
          :props="{ checkStrictly: true }"
          @change="onAreaChange"
          clearable
        >
        </el-cascader>
      </el-form-item>
    </el-form>
    <el-button type="primary" @click="create">立即创建</el-button>
    <el-button type="" @click="$router.go(-1)">返回</el-button>
  </div>
</template>

<script>
import { regionData } from "element-china-area-data";
import axios from "axios";
export default {
  data() {
    return {
      level: "医院",
      userInfo: JSON.parse(localStorage.getItem("logindata")),
      options: regionData,
      selectedOptions: [],
      props: {},
      params: {
        ishospital: 1,
        username: "",
        userfullname: "",
        password: "",
        categoryname: "医院",
        provinceid: "",
        cityid: "",
        districtid: "",
        rolecode: "05",
        rolename: "医院",
        areaItem: [],
        userarea: [],
        channeltype: "",
      },
      roleList: [],
      rules: {
        username: [
          { required: true, message: "请输入登录账户名", trigger: "blur" },
          {
            min: 6,
            max: 25,
            message: "登录账户应大于6位数并小于25位数",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "请输入账户密码", trigger: "blur" },
          {
            min: 6,
            max: 16,
            message: "密码长度应大于6位并小于16位数",
            trigger: "blur",
          },
        ],
        userfullname: [
          { required: true, message: "请输入用户姓名", trigger: "blur" },
        ],
        // userlevel: [
        //   { required: true, message: "请选择用户等级", trigger: "change" },
        // ],
        userarea: [
          { required: true, message: "请选择代理区域", trigger: "change" },
        ],
        role: [
          { required: true, message: "请选择账户角色", trigger: "change" },
        ],
      },
      areaList: [],
      url:
        "https://restapi.amap.com/v3/config/district?key=4f3b15d4e9ec39a20d3fb459c65ecf17",
    };
  },
  mounted() {
    this.asyncData();
    this.onLevelChange("4");
  },
  methods: {
    asyncData() {
      this.$api.getRoleApiList({}).then((res) => {
        console.log(res);
        this.roleList = res.data.data;
      });
    },
    onAreaChange(value) {
      console.log(value);
      this.params.areaItem = value;
    },
    onLevelChange(value) {
      let url;
      /**
       * userlevel用户等级	String	1 = 省级代理   2=市级代理   3=代理人   4=医院
       */
      switch (value) {
        case "1":
          url = this.url + "&subdistrict=1";
          break;
        case "2":
          url = this.url + "&subdistrict=2";
          break;
        case "3":
          url = this.url + "&subdistrict=3";
          break;
        case "4":
          url = this.url + "&subdistrict=3";
          break;
      }
      // this.formData.userlevel = value;
      this.loading = true;
      axios.get(url).then((res) => {
        console.log(res);
        let list = [];
        for (let pIndex in res.data.districts[0].districts) {
          let pItem = res.data.districts[0].districts[pIndex];
          let value = {};
          value.provinceid = pItem.adcode;
          value.label = pItem.name;
          value.value = pItem.adcode;
          if (pItem.districts && pItem.districts.length > 0) {
            value.children = [];
          }
          for (let cIndex in pItem.districts) {
            let cItem = pItem.districts[cIndex];
            let cValue = {
              cityid: cItem.adcode,
              label: cItem.name,
              value: cItem.adcode,
            };
            if (cItem.districts && cItem.districts.length > 0) {
              cValue.children = [];
            }
            for (let dIndex in cItem.districts) {
              let dItem = cItem.districts[dIndex];
              cValue.children.push({
                districtid: dItem.adcode,
                label: dItem.name,
                value: dItem.adcode,
              });
            }
            value.children.push(cValue);
          }
          list.push(value);
        }
        this.areaList = list;
        this.loading = false;
      });
    },
    onRoleChange(value) {
      this.info = this.roleList.find((e) => e.rolecode == value);
      this.params.rolename = this.info.rolename;
    },
    handleChange() {},
    async create() {
      this.params.provinceid = this.params.userarea[0];
      this.params.cityid = this.params.userarea[1];
      this.params.districtid = this.params.userarea[2];
      this.params.pwd = this.$md5(this.params.password);
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.$api.getApiInsertMuse(this.params).then((res) => {
            console.log(res);
            this.$message.success("添加成功");
            this.$router.go(-1);
          });
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
/deep/.el-input {
  width: 400px;
}
</style>