import request from "../network/Request";
import Vue from 'vue';
// 第二种封装方式 
function httpsRequest(url, params) {
 return new Promise((resolve, reject) => {
   request.post(url, params).then(res => {
    if (res.data.success == 1) {
     resolve(res.data.data)
    } else {
       reject(res)
       Vue.prototype.$message({
        showClose: true,
        message: res.data.errorMsg,
        type: 'warning'
       });
      }
   }).catch(()=>{
    Vue.prototype.$message({
     showClose: true,
     message: "请检查网络连接",
     type: "warning",
   });
    })
 })
}
export {
 httpsRequest
}