function  reports(httpsRequest) {
  return {
    requertReport(params) {
      return httpsRequest('/as.c.assess.report.insertAssessReport/1.0.1/action', params)
    },
    getReportDetail(params) {
      return httpsRequest('/as.m.assess.report.getAssessReportcontext/1.0.0/action', params)
    },
    sendReportEmail(params) {
      return httpsRequest('/as.c.assess.report.sendReportToEmail/1.0.0/action', params)
    },
    merrefund(params) {
      return httpsRequest('/as.m.order.merrefund/1.0.0/action', params)
    },
    checkAccountPwd(params) {
      return httpsRequest('/as.m.auth.checkAccountPwd/1.0.0/action', params)
    },
    
  }
}
export {reports}