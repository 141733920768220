const onceEdition = {
 getLoginUrl : "/as.m.user.login/1.0.0/action",//登录
 getCUserPageListUrl: "/as.m.cuser.getCUserPageList/1.0.0/action",//获取用户列表
 getOderPageListUrl: "/as.m.order.queryOrderPageList/1.0.0/action",//查看订单
 getInsertRoleUrl: "/as.m.role.insertOrUpdateRole/1.0.0/action",//新修改增角色
 getRoleListUrl: "/as.m.role.getMRolePageList/1.0.0/action",//获取角色列表
 getMUserPageListUrl: "/as.m.user.getMUserPageList/1.0.0/action",//获取用户列表
 getMUserCategoryListUrl: "/as.m.auth.getMUserCategoryList/1.0.0/action",//获取关联关系列表
 getMRoleSelectListUrl: "/as.m.role.queryMRoleSelectList/1.0.0/action",//查询所有角色
 getInsertMUserUrl: "/as.m.user.insertMUser/1.0.0/action",//新增用户
 getHospitalListUrl: "/as.m.user.getHospitalList/1.0.0/action",//获取医院列表
 getHospitalQRCodeUrl: "/as.m.user.insertHospitalQRcode/1.0.0/action",//生成二维码
 getUpdateMUserUrl: "/as.m.user.updateMUser/1.0.0/action",//修改修改用户接口
 getDeleteMUserUrl: "/as.m.user.deleteMUser/1.0.0/action",// 删除用户接口
 getAccessReportUrl: "/as.m.report.getAssessReportList/1.0.0/action",//获取报告
 getAssessReportAnswerInfoUrl: "/as.m.assess.report.getAssessReportAnswerInfo/1.0.0/action",//查看答题元数据
 getAssessQuotaPageListUrl: "/as.m.assess.quota.getAssessQuotaPageList/1.0.0/action",//查询配置表的评估配置
 getAssessQuotaDetailPageListUrl: "/as.m.assess.quota.getAssessQuotaDetailPageList/1.0.0/action",//查询配置表的评估建议区间
 getUpdateAssessQuotaDetailUrl: "/as.m.assess.quota.updateAssessQuotaDetail/1.0.0/action",//修改评估建议表数据
 getUserLevel1CategoryListUrl: "/as.m.category.selectUserLevel1CategoryList/1.0.0/action",//获取所有省代理列表
 getUserLevel2CategoryListUrl: "/as.m.category.selectUserLevel2CategoryList/1.0.0/action",//获取所有市代理列表
 getUserLevel3CategoryListUrl: "/as.m.category.selectUserLevel3CategoryList/1.0.0/action",//获取所有代理人代理列表
 getUserLevel4CategoryListUrl: "/as.m.category.selectUserLevel4CategoryList/1.0.0/action",//获取所有医院代理列表
 getUserEarningsListUrl: "/as.m.assess.order.getUserEarningsList/1.0.0/action",//获取收益列表
 getMUserOrderPageListUrl: "/as.m.order.queryOrderPageList/1.0.0/action",//三级代理账户查询自己相关的订单
 getMUserOderDataUrl: "/as.m.assess.order.getMUserOderData/1.0.0/action",//三级代理的统计数据
 getBindableMUserUrl: "/as.m.user.getBindableMUserPageList/1.0.0/action",//获取可绑定的上级用户
 updateMUserCategoryUrl: "/as.m.auth.updateMUserCategory/1.0.0/action",//绑定上级
 updateHospitalCategoryUrl: "/as.m.user.updateHospitalCategory/1.0.0/action",//医院绑定上级
 getHospitalDetailUrl: "/as.m.user.getHospitalDetail/1.0.0/action",//获取医院详情信息
 setHospitalFreeCountUrl: "/as.m.user.setHospitalFreeCount/1.0.0/action",//设置医院免费次数
 clearMUserCategoryUrl: "/as.m.user.clearMUserCategory/1.0.0/action",//清除代理关系
 getTreeMUserListUrl: "/as.m.user.getTreeMUserList/1.0.0/action",//查看代理关系下的所有用户
 insertUserFreeAssessOrderUrl: "/as.m.order.insertUserFreeAssessOrder/1.0.0/action",//医院账户为用户添加免费次数
 getReportStatistics: "/as.m.assess.report.statistics/1.0.0/action",//报告结果等级统计图
 getCUserProfile: "/as.m.user.profile/1.0.0/action",//获取客户端用户的基础信息和报告列表
 updateHospitalFreeStatus:"/as.m.user.updateHospitalFreeStatus/1.0.0/action"

}
export {onceEdition}
