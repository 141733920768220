import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = () => {
  const modules = {}

  const appFiles = require.context(`./modules`, true, /\.store\.js$/)
  appFiles.keys().forEach((v) => {
    const fileName = v.split('/')[v.split('/').length - 1]
    const key = fileName.replace(/\.\//g, '').replace(/\.store\.js/g, '')
    modules[key] = appFiles(v).default
  })
 console.log(modules)
  return new Vuex.Store({
    modules,
  })
}

export default store
