<template>
  <div class="container">
    <div class="toolbar">
      <div style="flex: 1">
        <span style="font-size: 13px; color: #666">搜索姓名：</span>
        <el-input
          placeholder="搜索姓名"
          v-model="userfullname"
          style="width: 280px"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="searchList"
          ></el-button>
        </el-input>
      </div>
    </div>
    <div>
      已绑定服务商： <span class="" v-if="parent.length == 0"> 未绑定 </span>
    </div>
    <el-table
      v-loading="loading"
      :data="parent"
      border
      size="small"
      style="width: 100%; margin-top: 20px"
      v-if="parent.length > 0"
    >
      <el-table-column prop="categoryname" label="账户等级" min-width="150">
      </el-table-column>
      <el-table-column prop="userfullname" label="姓名" width="220">
      </el-table-column>
      <el-table-column prop="username" label="登陆账号" width="220">
      </el-table-column>
      <el-table-column label="代理区域" min-width="220">
        <template slot-scope="scope">
          <span>{{
            scope.row.provincename +
            " " +
            scope.row.cityname +
            " " +
            scope.row.districtname
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="createtime" label="注册时间" width="220">
      </el-table-column>
    </el-table>
    <!-- <div style="margin-bottom: 10px">
      <span>医院联系人：</span>
      <span v-if="current.category3info && current.category3info.userfullname">
        {{ current.category3info.userfullname }}
      </span>
      <span
        v-else-if="current.category2info && current.category2info.userfullname"
      >
        {{ current.category2info.userfullname }}
      </span>
      <span
        v-else-if="current.category1info && current.category1info.userfullname"
      >
        {{ current.category1info.userfullname }}
      </span>
      <span
        v-else-if="current.category0info && current.category0info.userfullname"
      >
        {{ current.category0info.userfullname }}
      </span>
    </div> -->
    <el-table
      v-loading="loading"
      :data="tableData"
      border
      size="small"
      style="width: 100%; margin-top: 20px"
    >
      <el-table-column type="index" label="#" width="50"> </el-table-column>
      <el-table-column prop="categoryname" label="账户等级" min-width="150">
      </el-table-column>
      <el-table-column prop="userfullname" label="姓名" width="220">
      </el-table-column>
      <el-table-column prop="username" label="登陆账号" width="220">
      </el-table-column>
      <el-table-column label="代理区域" min-width="220">
        <template slot-scope="scope">
          <span>{{
            scope.row.provincename +
            " " +
            scope.row.cityname +
            " " +
            scope.row.districtname
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="createtime" label="注册时间" width="220">
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="100">
        <template slot-scope="scope">
          <el-button
            @click="bindParent(scope.row)"
            type="primary"
            plain
            size="mini"
            :disabled="scope.row.categorycode == current.categorycode"
            >绑定</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div class="footer">
      <el-pagination
        background
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        :page-sizes="[10, 15, 30]"
        :page-size="pageSize"
        layout="total,sizes, prev, pager, next, jumper"
        :total="totalData"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import api from "@/api";

export default {
  name: "AddCategory",
  props: {
    current: Object,
    closeAndRefresh: Function,
  },
  data() {
    return {
      tableData: [],
      pageIndex: 1,
      pageSize: 15,
      totalData: 0,
      loading: false,
      userfullname: "",
      parantCode: "",
      parent: [],
    };
  },
  mounted() {
    this.initList();
  },
  methods: {
    initList() {
      this.loading = true;
      api
        .getBindableMUserList({
          userlevel: this.current.categorylevel.toString(),
          page: this.pageIndex,
          pageSize: this.pageSize,
          userfullname: this.userfullname.toString(),
        })
        .then((res) => {
          if (res.data.success === "1") {
            this.totalData = res.data.data.totalRecords;
            this.tableData = res.data.data.list;
            this.parantCode = this.current.parent
              ? this.current.parent.categorycode
              : "";
            if (this.parantCode) {
              const parent = this.tableData.find(
                (e) => e.categorycode == this.parantCode
              );
              if (parent) this.parent = [parent];
            }
          } else {
            this.$message({
              showClose: true,
              message: res.data.errorMsg,
              type: "warning",
            });
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.$message({
            showClose: true,
            message: "请检查网络连接",
            type: "warning",
          });
        });
    },
    handleCurrentChange(val) {
      this.pageIndex = val;
      this.initList();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.initList();
    },
    onTabChange() {
      this.initList();
    },
    bindParent(val) {
      this.closeAndRefresh(this.current.categorycode, val.categorycode);
    },
    searchList() {
      this.pageIndex = 1;
      this.initList();
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  padding: 5px;
}
.footer {
  display: flex;
  height: 100px;
  justify-content: center;
  align-items: center;
}
.toolbar {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
}
</style>