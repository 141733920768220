import request from "../network/Request";
import APIData from "../network/APIData";

function userLogin(p) {
  return request.post(APIData.getLoginUrl,p);
}

function getCUserPageList(p) {
  return request.post(APIData.getCUserPageListUrl,p);
}

function getOrderPageList(p) {
  return request.post(APIData.getOderPageListUrl,p);
}

function insertOrUpdateRole(p) {
  return request.post(APIData.getInsertRoleUrl,p);
}

function getRoleList(p) {
  return request.post(APIData.getRoleListUrl,p);
}

function getMUserPageList(p) {
  return request.post(APIData.getMUserPageListUrl,p);
}

function getMUserCategoryList(p) {
  return request.post(APIData.getMUserCategoryListUrl,p);
}

function queryMRoleSelectList(p) {
  return request.post(APIData.getMRoleSelectListUrl,p);
}

function insertMUser(p) {
  return request.post(APIData.getInsertMUserUrl,p);
}

function getHospitalList(p) {
  return request.post(APIData.getHospitalListUrl,p);
}

function createHospitalQRCode(p) {
  return request.post(APIData.getHospitalQRCodeUrl,p);
}

function updateMUser(p) {
  return request.post(APIData.getUpdateMUserUrl,p);
}

function deleteMUser(p) {
  return request.post(APIData.getDeleteMUserUrl,p);
}

function getAccessReport(p) {
  return request.post(APIData.getAccessReportUrl,p);
}

function getAssessReportAnswerInfo(p) {
  return request.post(APIData.getAssessReportAnswerInfoUrl,p);
}

function getAssessQuotaPageList(p) {
  return request.post(APIData.getAssessQuotaPageListUrl,p);
}

function updateAssessQuotaDetail(p) {
  return request.post(APIData.getUpdateAssessQuotaDetailUrl,p);
}

function getAssessQuotaDetailPageList(p) {
  return request.post(APIData.getAssessQuotaDetailPageListUrl,p);
}

function getUserLevel1CategoryList(p) {
  return request.post(APIData.getUserLevel1CategoryListUrl,p);
}

function getUserLevel2CategoryList(p) {
  return request.post(APIData.getUserLevel2CategoryListUrl,p);
}

function getUserLevel3CategoryList(p) {
  return request.post(APIData.getUserLevel3CategoryListUrl,p);
}

function getUserLevel4CategoryList(p) {
  return request.post(APIData.getUserLevel4CategoryListUrl,p);
}

function getUserEarningsList(p) {
  return request.post(APIData.getUserEarningsListUrl,p);
}

function getMUserOrderPageList(p){
  return request.post(APIData.getMUserOrderPageListUrl,p);
}

function getMUserOderData(p){
  return request.post(APIData.getMUserOderDataUrl,p);
}

function getBindableMUserList(p){
  return request.post(APIData.getBindableMUserUrl,p);
}

function updateMUserCategory(p){
  return request.post(APIData.updateMUserCategoryUrl,p);
}

function updateHospitalCategory(p){
  return request.post(APIData.updateHospitalCategoryUrl,p);
}

function getHospitalDetail(p){
  return request.post(APIData.getHospitalDetailUrl,p);
}

function setHospitalFreeCount(p){
  return request.post(APIData.setHospitalFreeCountUrl,p);
}

function clearMUserCategory(p){
  return request.post(APIData.clearMUserCategoryUrl,p);
}

function getTreeMUserList(p){
  return request.post(APIData.getTreeMUserListUrl,p);
}
function getReportAction(p){
  return request.post(APIData.getReportAction,p);
}
function getRportList(p) {
  return request.post(APIData.getRportList,p);
}
function getReportDetail(p) {
  return request.post(APIData.getReportDetail,p);
}
function createReportDetail(p) {
  return request.post(APIData.createReportDetail,p);
}
function getRoleApiList(p) {
  return request.post(APIData.getRoleApiList,p);
}
function getApiInsertMuse(p) {
  return request.post(APIData.getApiInsertMuse,p);
}

function getReportEcharts(p) {
  return request.post(APIData.getReportEcharts,p);
}

function insertUserFreeAssessOrder(p) {
  return request.post(APIData.insertUserFreeAssessOrderUrl,p);
}

function getReportStatistics(p) {
  return request.post(APIData.getReportStatistics,p);
}
function getCUserProfile(p) {
  return request.post(APIData.getCUserProfile,p);
}

function updateHospitalFreeStatus(p) {
  return request.post(APIData.updateHospitalFreeStatus,p);
}
export default {
  getReportEcharts,
  getApiInsertMuse,
  getRoleApiList,
  createReportDetail,
  getReportDetail,
  getRportList,
  getCUserPageList: getCUserPageList,
  userLogin: userLogin,
  getOrderPageList: getOrderPageList,
  insertOrUpdateRole: insertOrUpdateRole,
  getRoleList: getRoleList,
  getMUserPageList: getMUserPageList,
  getMUserCategoryList: getMUserCategoryList,
  queryMRoleSelectList: queryMRoleSelectList,
  insertMUser: insertMUser,
  getHospitalList: getHospitalList,
  createHospitalQRCode: createHospitalQRCode,
  deleteMUser: deleteMUser,
  updateMUser: updateMUser,
  getAccessReport: getAccessReport,
  getAssessReportAnswerInfo: getAssessReportAnswerInfo,
  getAssessQuotaPageList: getAssessQuotaPageList,
  updateAssessQuotaDetail: updateAssessQuotaDetail,
  getAssessQuotaDetailPageList: getAssessQuotaDetailPageList,
  getUserLevel1CategoryList: getUserLevel1CategoryList,
  getUserLevel2CategoryList: getUserLevel2CategoryList,
  getUserLevel3CategoryList: getUserLevel3CategoryList,
  getUserLevel4CategoryList: getUserLevel4CategoryList,
  getUserEarningsList: getUserEarningsList,
  getMUserOrderPageList: getMUserOrderPageList,
  getMUserOderData: getMUserOderData,
  getBindableMUserList: getBindableMUserList,
  updateMUserCategory: updateMUserCategory,
  updateHospitalCategory: updateHospitalCategory,
  getHospitalDetail: getHospitalDetail,
  setHospitalFreeCount: setHospitalFreeCount,
  clearMUserCategory: clearMUserCategory,
  getTreeMUserList: getTreeMUserList,
  getReportAction,
  insertUserFreeAssessOrder: insertUserFreeAssessOrder,
  getReportStatistics: getReportStatistics,
  getCUserProfile: getCUserProfile,
  updateHospitalFreeStatus:updateHospitalFreeStatus
}
